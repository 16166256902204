/*******************************************/
/* Terratonepull Recent Transactions Layer */
/* Version 3.0                             */
/*******************************************/

import * as olControl from "ol/control";
import * as olStyle from "ol/style";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import ClusterSource from "ol/source/Cluster";
import { fromLonLat } from "ol/proj";

// Global Function: To add an element under the parentElement
function addElement(parentElement, tag, attributes = null, innerHTML = null, parentObject = null) {
  var elmt = document.createElement(tag);
  if (attributes) {
    for (let key in attributes) {
      elmt.setAttribute(key, attributes[key]);
    }
  }
  if (innerHTML) {
    elmt.innerHTML = innerHTML;
  }
  if (parentObject) {
    elmt.parentObj = parentObject;
  }
  if (parentElement) {
    parentElement.appendChild(elmt);
  }
  return elmt;
} // END OF addElement global function


class RangeFilter {
  constructor(parentElement, sectionName, choices) {
    var sectionHead = addElement(parentElement, "div", {class: "filter-title"}, sectionName);
    var clearBtnLink = addElement(sectionHead, "div", {class: "filter-buttonLink"}, "Clear", this);
    clearBtnLink.addEventListener("click", function() {
      this.parentObj.clearRangeFilter();
    });

    var optHtml = "<option value=''></option>";
    for (let choice of choices) {
      optHtml += "<option value='" + choice.value + "'>" + choice.text + "</option>";
    }
    var dropdownTable = addElement(parentElement, "table", {style: "clear:both;"});
    var fromDropdownRow = addElement(dropdownTable, "tr", null, "<td>From:</td>");
    var toDropdownRow = addElement(dropdownTable, "tr", null, "<td>To:</td>");
    var fromDropdownCell = addElement(fromDropdownRow, "td", {style: "padding-left:3px;"});
    var toDropdownCell = addElement(toDropdownRow, "td", {style: "padding-left:3px;"});
    this.fromDropdown = addElement(fromDropdownCell, "ons-select", null, optHtml, this);
    this.toDropdown = addElement(toDropdownCell, "ons-select", null, optHtml, this);
    this.fromDropdown.addEventListener("change", function() {
      this.parentObj.updateToRange();
    });
    this.toDropdown.addEventListener("change", function() {
      this.parentObj.updateFromRange();
    });
  }

  // FUNCTION: Refrech the from-range dropdown filter
  updateFromRange() {
    for (let optElement of this.fromDropdown.options)
      optElement.disabled = (optElement.value != "" && this.toDropdown.value != "" && +optElement.value > +this.toDropdown.value);
  }

  // FUNCTION: Refrech the to-range dropdown filter
  updateToRange() {
    for (let optElement of this.toDropdown.options)
      optElement.disabled = (optElement.value != "" && this.fromDropdown.value != "" && +optElement.value < +this.fromDropdown.value);
  }
  
  // FUNCTION: Clear the range filter
  clearRangeFilter() {
    this.fromDropdown.selectedIndex = 0;
    this.toDropdown.selectedIndex = 0;
  }
  
  // FUNCTION: Check if a value is within the filter range
  rangeMet(valueToChk) {
    var minValue = this.fromDropdown.value;
    var maxValue = this.toDropdown.value;
    if ((minValue != "" && +valueToChk < +minValue) || (maxValue != "" && +valueToChk > +maxValue)) {
      return false;
    }
    return true;
  }
} // END OF RangeFilter class


class OptionFilter {
  constructor(parentElement, sectionName, optTexts) {
    const symbolSelectAll = "<span style='font-size:150%'>&#x2611;</span>All";
    const symbolUnselectAll = "<span style='font-size:150%'>&#x2610;</span>All";

    var sectionHead = addElement(parentElement, "div", {class: "filter-title"}, sectionName);
    var unselectAllBtnLink = addElement(sectionHead, "div", {class: "filter-buttonLink"}, symbolUnselectAll, this);
    var selectAllBtnLink = addElement(sectionHead, "div", {class: "filter-buttonLink"}, symbolSelectAll, this);
    unselectAllBtnLink.addEventListener("click", function() {
      this.parentObj.changeAllValues(false);
    });
    selectAllBtnLink.addEventListener("click", function() {
      this.parentObj.changeAllValues(true);
    });
        this.chkboxes = {};
    for (let optText of optTexts) {
      this.chkboxes[optText] = addElement(parentElement, "input", {type: "checkbox", checked: true});
      addElement(parentElement, "span", {style: "padding-left:5px;"}, optText, this)
        .addEventListener("click", function() {
          this.parentObj.flipValue(optText);
        })
      addElement(parentElement, "br");
    }
  }

  // FUNTION: Change the values of all filter checkboxes
  changeAllValues(value) {
    for (let chkbox of Object.values(this.chkboxes)) {
      chkbox.checked = value;
    }
  }

  // FUNCTION: Flip the value of the checkbox with chkboxText
  flipValue(chkboxText) {
    var chkbox = this.chkboxes[chkboxText];
    if (chkbox) {
      chkbox.checked = !chkbox.checked;
    }
  }
  
  // FUNCTION: Check if the checkbox with chkboxText is selected.
  //    If there is a check box for "Others", specify the text of that checkbox as chkboxTextForOthers.
  //    This function returns true if there is no checkbox with chkboxText but there is a checkbox for "Others".
  isSelected(chkboxText, chkboxTextForOthers = null) {
    if (chkboxText == undefined || chkboxText == null) {
      return false;
    }
    var chkbox = this.chkboxes[chkboxText];
    if (chkbox) {
      return chkbox.checked;
    } else if (chkboxTextForOthers) {
      return this.chkboxes[chkboxTextForOthers].checked;
    } else {
      return false;
    }
  }
} // END OF OptionFilter class


class RecentTransLayer {
  constructor() {
    // Import style sheet
    var stylesheetLink = document.createElement("link");
    stylesheetLink.type = "text/css";
    stylesheetLink.rel = "stylesheet";
    stylesheetLink.href = "recent-trans-styles.css";
    document.head.appendChild(stylesheetLink);

    // Declare Public Constants
    this.mostRecentTransYear = 2020;
    this.numTransYears = 5;
    this.recentTransClusterDistance = 40;
    this.recentTransClusterRadius = 15;
    this.featureTypeMarker = "transactionMarker";
    this.featureTypeCluster = "transactionCluster";
    this.recentTransAssetTypes = {
      "HOT": "Hotel",
      "ICI": "ICI Land",
      "IND": "Industrial",
      "APT": "Multi-Family",
      "OFF": "Office",
      "RET": "Retail",
      "RLN": "Residential Land"
      //"RLT": "Residential Lots"
    };
    this.recentTransSaleTypesSelections =
      ["Market", "Non-Arms", "Share Sale", "Distress", "Business Transaction", "Right To Purchase", "Others"];

    // Declare Public Variables
    this.recentTrans = [];
    this.recentTransCache = [];
    this.realnetFileType = (location.hostname=="localhost" || location.hostname=="127.0.0.1" ) ?
      ".json" : ".json.gz";
    this.recentTransLayer = null;  // define the layer as a global variable so that it can be updated.
  }

  // FUNCTION: Create/Initialize the main transaction filter HTML element
  initTransFilterElement() {
    var mainFilterElmt = document.createElement("form");
    mainFilterElmt.className = "filter-main-form";
    mainFilterElmt.innerHTML = "<div class='filter-header-footer'>Transaction Filter</div>";

    // Create Year Selection Buttons
    var segmentDiv=document.createElement("div");
    var segment=document.createElement("ons-segment");
    var buttonYears = [];
    for (var transYear = this.mostRecentTransYear; transYear > this.mostRecentTransYear - this.numTransYears; transYear--) {
      buttonYears.push(
        addElement(segment, "button", {id: "btn"+transYear, style: "width:50px;"}, transYear)
      );
    }
    segmentDiv.appendChild(segment);
    mainFilterElmt.appendChild(segmentDiv);

    // Create Month Range Dropdown Menus
    const monthNames =
        ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    var monthChoices = [];
    for (let month = 1; month <= 12; ++month) {
      monthChoices.push({value: month, text: monthNames[month - 1]});
    }
    this.dateRangeFilter = new RangeFilter(mainFilterElmt, "Date Range", monthChoices);

    // Create Price Range Dropdown Menus
    const maxPrice = 100000000;
    var priceOptions = [];
    for (var price = 0, priceInc = 500000; price <= maxPrice; price += priceInc) {
      priceOptions.push({value: price, text: "$" + price.toLocaleString("en-CA")});
      if (price == priceInc * 20) priceInc *= 10;
    }
    this.priceRangeFilter = new RangeFilter(mainFilterElmt, "Sold Price", priceOptions);

    // Create Asset Type Filter
    this.assetTypesFilter = new OptionFilter(mainFilterElmt, "Asset Types", Object.values(this.recentTransAssetTypes));
    
    // Create Sale Type Filter
    this.saleTypesFilter = new OptionFilter(mainFilterElmt, "Sale Types", this.recentTransSaleTypesSelections);

    // Create Done and Reset All buttons
    var footerDiv = addElement(mainFilterElmt, "div", {class: "filter-title"});
    var doneButton = addElement(footerDiv, "ons-button",
      {modifier: "large"}, "Done", this);
    var resetAllButton = addElement(footerDiv, "ons-button",
      {modifier: "large quiet"}, "Reset All Filters", this);
    doneButton.addEventListener("click", function() {
      this.parentObj.execFilter()
    });
    resetAllButton.addEventListener("click", function() {
      this.parentObj.clearAllTransFilters()
    });

    // Return the filter element
    return mainFilterElmt;
  }

  // FUNCTION: Clear all filters
  clearAllTransFilters() {
    this.dateRangeFilter.clearRangeFilter();
    this.priceRangeFilter.clearRangeFilter();
    this.assetTypesFilter.changeAllValues(true);
    this.saleTypesFilter.changeAllValues(true);
  }
  
  // FUNCTION: Execute the filter
  execFilter() {
    if (this.transFilter.style.display == "block") {
      this.transFilter.style.display = "none";
      this.setTransactionSource();
    } else {
      this.transFilter.style.display = "block";
    }
  }
  
  // FUNCTION: Reset the transaction source per filter selection
  setTransactionSource() {
    function setSourceAfterLoadingData(that) {
      var markers = [];
      var transCount = that.recentTrans.length;
      for (let i = 0; i < transCount; ++i) {
        var dateStr = that.recentTrans[i].EventDate.substr(5,2);
        var assetType = that.recentTransAssetTypes[that.recentTrans[i].InventoryNumber.substr(3,3)];
        var saleType = that.recentTrans[i].SaleType;
        saleType = saleType == null ? "Others" : saleType.split(" - ")[0];
        if (that.priceRangeFilter.rangeMet(that.recentTrans[i].Price) &&
            that.dateRangeFilter.rangeMet(dateStr) &&
            that.assetTypesFilter.isSelected(assetType) &&
            that.saleTypesFilter.isSelected(saleType, "Others")) {
          var marker = new Feature({
            geometry: new Point(fromLonLat([that.recentTrans[i].Longitude, that.recentTrans[i].Latitude])),
            index: i
          });
          markers.push(marker);
        }
      }
      var recentTransVectorSource = new VectorSource({features: markers});
      var recentTransClusterSource = new ClusterSource({distance: that.recentTransClusterDistance, source: recentTransVectorSource});
      that.recentTransLayer.setSource(recentTransClusterSource);
    } // End Sub-Function setSourceAfterLoadingData

    var activeYearBtnIdx=document.querySelector('ons-segment').getActiveButtonIndex();
    if (this.recentTransCache[activeYearBtnIdx] == null) {
      var jsonFileName = "data/RealnetOutput" + (this.mostRecentTransYear - activeYearBtnIdx) + this.realnetFileType;
      var that = this;
      var xobj = new XMLHttpRequest();
      xobj.overrideMimeType("application/json");
      xobj.open('GET', jsonFileName, true);
      xobj.onreadystatechange = function () {
        if (xobj.readyState == 4 && xobj.status == "200") {
          that.recentTrans = JSON.parse(xobj.responseText);
          console.log("Year", that.mostRecentTransYear - activeYearBtnIdx, "recent transaction data loaded:", that.recentTrans.length);
          that.recentTransCache[activeYearBtnIdx] = that.recentTrans;
          setSourceAfterLoadingData(that);
        }
      };
      xobj.send(null);
    } else {
      this.recentTrans = this.recentTransCache[activeYearBtnIdx];
      setSourceAfterLoadingData(this);
    }
  }

  // FUNCTION: Create the recent transaction layer
  createLayer(map, mapContainerId, pushButtonLocClass, filterButtonLocClass) {
    // Create transaction layer push button
    const pushButton = document.createElement("div");
    pushButton.className = "ol-control ol-unselectable " + pushButtonLocClass;
    pushButton.innerHTML = '<button><i class="fa fa-file-contract"></i></button>';
    pushButton.title = "Recent Transactions";
    pushButton.addEventListener("click", function recentTransClick() {
      map.getLayers().forEach(function(lyr) {
        // console.log(lyr.get('name'));
        if (lyr.get('name') === 'recentTrans') {
          var is_visible = lyr.get('visible');
          lyr.setVisible(!is_visible);
        }
      });
    });
    map.addControl(
      new olControl.Control({
        element: pushButton,
      })
    );
    
    // Create filter push button
    var filterButton = addElement(null, "div",
      {class: "filter-button ol-control ol-unselectable " + filterButtonLocClass},
      "<button title='Filter'><i class='fa fa-filter'></i></button >",
      this
    );
    filterButton.addEventListener("click", function () {
      this.parentObj.execFilter();
    });
    map.addControl(
      new olControl.Control({
        element: filterButton
      })
    );

    // Create Filter Container
    var filterContainer = document.createElement("div");
    filterContainer.className = "filter-container";
    //var mapContainerId = map.get('target');
    this.transFilter = this.initTransFilterElement();
    filterContainer.appendChild(this.transFilter);
    document.getElementById(mapContainerId).appendChild(filterContainer);
  
    // Set Recent-Transaction Marker/Cluster Styles
    var markerImage = new olStyle.Icon(({
      anchor: [0.5, 1], 
      crossOrigin: "anonymous", 
      scale: 0.1, 
      src: "img/home-map-marker.png"
    }));
    var clusterImage = new olStyle.Circle({
      radius: this.recentTransClusterRadius,
      stroke: new olStyle.Stroke({color: "#fff"}),
      fill: new olStyle.Fill({color: "#0099e6"})
    });
    var clusterTextFill = new olStyle.Fill({color: '#fff'});

    // Create the Recent Transaction Cluster Layer
    var that = this;
    this.recentTransLayer = new VectorLayer({
      title: "Recent Transaction",
      name:"recentTrans",
      visible: false,
        // Perry extent
        //extent:[-13708636.322763294,6318548.145849912,-13706072.212231088,6321196.52292543],
        // Jordon extent
        //extent:[-13658065.687033301,6292026.515496633,-13655257.382381761,6295304.1124374205],
        
      style: function(feature) {
        var size = feature.get("features").length;
        if (size == 1) {
          feature.featureType = that.featureTypeMarker;
          var style = new olStyle.Style({
            image: markerImage
          });
        } else {
          feature.featureType = that.featureTypeCluster;
          var style = new olStyle.Style({
            image: clusterImage,
            text: new olStyle.Text({text: size.toString(), fill: clusterTextFill})
          });
        }
        return style;
      }
    });
    this.recentTransLayer.setZIndex(1);
    
    // Set the data source of the Recent Transaction Layer
    this.setTransactionSource();

    // Sync the visibility of the Filter Icon and the Recent Transaction Layer
    this.recentTransLayer.on("change:visible", function(evt) {
      if (evt.oldValue) {
        filterContainer.style.display = "none";
        filterButton.style.display = "none";
      } else {
        filterContainer.style.display = "block";
        filterButton.style.display = "block";
      }
    });

    // Add the Recent Transaction Layer to the map
    map.addLayer(this.recentTransLayer);
  }

  // FUNCTION: Get the recent transactions from the feature being clicked
  getRecentTransactions(clickedFeature) {
    var innerFeatures = clickedFeature.get('features');
    var transactions = [];
    for (let innerFeature of innerFeatures) {
      let index = innerFeature.get('index');
      transactions.push(this.recentTrans[index]);
    }
    return transactions;
  }
}

export default new RecentTransLayer();
