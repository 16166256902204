import TileLayer from "ol/layer/Tile"
import OSM from "ol/source/OSM"
import XYZ from 'ol/source/XYZ'
import TileWMS from 'ol/source/TileWMS'
import KML from 'ol/format/KML'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector';
import {Fill, Stroke, Style, Text} from 'ol/style';
import fb from "../firebase"
import { store } from "../store";
import { extension } from "showdown"





var style = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.6)',
  }),
  stroke: new Stroke({
    color: '#319FD3',
    width: 1,
  }),
  text: new Text({
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

var indmap=function(){
  var   indlayer = new VectorLayer({

    source: new VectorSource({
      url: 'data/GV_Industrial.kml',
      format: new KML(),
    }),


  })
  indlayer.getSource().on('change', function(evt){
    const source = evt.target;
    if (source.getState() === 'ready') {
      const numFeatures = source.getFeatures().length;
      console.log("Count after change: " + numFeatures);
    }
  });
  return indlayer
};

var streetmap=function(){
    var mapbaselayer=null;
            mapbaselayer=new TileLayer({
                source: new OSM(),
                type:'base',
                baseLayer:true
            })
    return mapbaselayer;
};
var googlemap=function(){
  var mapbaselayer=null;
          mapbaselayer=new TileLayer({
              source: new XYZ({
                attributions: '© <a href="https://cloud.google.com/maps-platform/terms/" target="_blank">Powered By Google. </a>',
                //attributionsCollapsible: false,
                url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
              }),
              type:'base',
              baseLayer:true
          })
  return mapbaselayer;
};
var satellitemap=function(){
    var additonallayers=null;
            additonallayers=new TileLayer({
                title:'Satellite view',
                name:'GSatellite',
                visible:false,
              source: new XYZ({
                attributions: ['Powered by Esri',
                               'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                maxZoom: 23
              })
            });
    return additonallayers;
}
var bcparcelmap=function(){
    var parcelLayer = new TileLayer({
        title: 'Parcel lines',
        displayInLayerPopup:'0',
        opacity:0.7,
        minZoom:12,
        bgcolor:'0x80BDE3',
        
        // Perry extent
        /*
        extent:[-13708573.09907316,
          6317899.993763422,
          -13706121.72827147,
          6319568.366776173
        ], */
        /*
        extent:[-13708636.322763294,
          6318548.145849912,
          -13706072.212231088,
          6321196.52292543
        ], */

         //extent:[-13691698.770829778,6311346.989584832,-13688330.310083484,6312792.094212464],
         // Jordan extent
        // extent:[-13658065.687033301,6292026.515496633,-13655257.382381761,6295304.1124374205],
        extent:[-13658085.19234013,6291522.569007311,-13652372.49878735,6295327.205524699],
        // Richmond area west of No2 ,  Request by David for App on 9 Mar 2021
        //extent:[-13722433.185728591,
        //          6294292.953649552,
        //        -13709974.340310227,
        //          6305043.228675206],
        source: new TileWMS({
      
          url:
            "https://openmaps.gov.bc.ca/geo/pub/WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW/ows",
          crossOrigin: null,
          params: {
            LAYERS: "WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW",
          }
        })
        
      });
    return parcelLayer;
}
var bookmarksmap=function(){
   var style = [
    new Style({
      stroke: new Stroke({
        color: "black",
        width: 4,
      }),
      fill: new Fill({
        color: "green",
      }),
    }),
  ];
  var bookmarkLayer=new VectorLayer({
    title:'Bookmarks',
    name:'Bookmarks',
    source: new VectorSource(),
    style : style ,
    visible:false
  
  })
  
  return bookmarkLayer
}
var mapconfig={
    //x:-123.1175904,     
    //y:49.28764,
    //CBRE

    //Centre of Perry extension
    /*
    x: -123.13482650106631,
    y: 49.26326729674801,
    */

    // Centre of Ilya extension
    //x: -122.9823734,
    //y: 49.2200834,

    //Centre of Jordan extension
    x: -122.6806331,
    y: 49.1104565,
    zoom:15,          
    streetmap:streetmap(),
    googlemap:googlemap(),
    satellitemap:satellitemap(),
    bcparcelmap:bcparcelmap(),
    bookmarksmap:bookmarksmap(),
    indmap:indmap(),
    

};
export default mapconfig



